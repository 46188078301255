import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import classnames from 'classnames';
import { get } from 'lodash';
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { EventToolbox } from 'client/utils/event-toolbox';
import { TrackingConstant } from 'client/tracking/constant';
import { CmsModel } from 'client/data/models/cms';
import { parseContent } from 'client/data/cms/content';
import { VehicleEntities } from 'client/data/models/vehicle';
import {
  buildLatestVideosForGenerationPath,
  buildVideosPath,
  MediaEntities,
  MediaModel,
} from 'client/data/models/media';
import { getFullImageUrl, isDamSrc, YOUTUBE_PREVIEW_SIZE } from 'client/utils/image-helpers';
import { EmbeddedYoutubeAsync } from 'site-modules/shared/components/video/embedded-youtube/embedded-youtube-async';
import { ContentCollapse } from 'site-modules/shared/components/content-collapse/content-collapse';
import { ArrowLink } from 'site-modules/shared/components/arrow-link/arrow-link';
import { getRankingsContentPath } from 'site-modules/shared/utils/upper-funnel/content-path';
import { TruncatedTextLite } from 'site-modules/shared/components/truncated-text-lite/truncated-text-lite';
import { PlaylistItem } from './playlist-item';

import './video-module.scss';

const SHOW_PLAYLIST_NUMBER = 4;
const MAX_DESCRIPTION_LENGTH = { true: 175, false: 285 };
const MAX_DESCRIPTION_LENGTH_MODULAR = { true: 175, false: 375 };

function addVehicleVideoTitleFallback(videos, { vehicle }) {
  return videos.map(video => ({
    ...video,
    videoTitle: video.videoTitle || `${vehicle.year} ${vehicle.make.name} ${vehicle.model.name} Video`,
  }));
}

export function VideoModuleUI({
  vehicle,
  videos,
  pageName,
  playerComponent: PlayerComponent,
  playlistTitle,
  videoLargeColumnSize,
  playlistLargeColumnSize,
  playlistHasCurrentVideo,
  isPlaylistSameHeight,
  seeAllLink,
  hideVideoDetails,
  className,
  playlistClassName,
  youtubeThumbnailSize,
  lazyLoadPreview,
  isMobile,
  isModularDesign,
  // Please set this prop to "false" for progressive components
  // otherwise Google crawler may render schema wrapper twice which results in errors
  withSchemaMeta,
}) {
  const currentVideos = useMemo(() => (videos ? videos.filter(({ videoId }) => videoId) : []), [videos]);

  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [isPlaying, setPlaying] = useState(false);

  useEffect(() => {
    setCurrentVideoIndex(0);
  }, [currentVideos]);

  const loadVideo = useCallback(
    (video, index) => e => {
      e.preventDefault();
      setCurrentVideoIndex(index);
      setPlaying(false);
    },
    []
  );

  const changeHandler = useCallback(
    event => {
      const playerState = window.YT.PlayerState;
      switch (event.data) {
        case playerState.ENDED: {
          if (currentVideos[currentVideoIndex + 1]) {
            setCurrentVideoIndex(currentVideoIndex + 1);
            setPlaying(false);
          }
          break;
        }
        case playerState.PLAYING: {
          setPlaying(true);
          break;
        }
        default: {
          setPlaying(false);
        }
      }
    },
    [currentVideoIndex, currentVideos]
  );

  if (!currentVideos[currentVideoIndex]) {
    return null;
  }
  const {
    thumbnailURL,
    uploadDate,
    videoTitle,
    videoDescription,
    videoTranscript,
    videoId,
    year: currentVideoYear,
  } = currentVideos[currentVideoIndex];

  // Check explicitly for null to determine if videos obj has returned and fire only after
  const { SYNPARTNER, SYSTEM_ACTION_CATEGORY, ACTION_SHOW_CONTENT } = TrackingConstant;
  const action = {
    event_type: 'action_completed',
    event_data: {
      action_category: SYSTEM_ACTION_CATEGORY,
      action_name: ACTION_SHOW_CONTENT,
      synpartner: SYNPARTNER,
      action_cause: 'page_load',
      subaction_name: 'show_video_yes_no',
      value: 'yes',
      ...(pageName ? { page_name: pageName } : {}),
      ...(pageName ? { page_category: pageName } : {}),
    },
  };
  EventToolbox.fireTrackAction(action);

  const playlistVideos = playlistHasCurrentVideo ? videos : videos.filter(video => videoId !== video.videoId);

  let playlistShowPart = playlistVideos;
  let playlistCollapsePart;

  if (isMobile) {
    playlistShowPart = playlistVideos.slice(0, SHOW_PLAYLIST_NUMBER);
    playlistCollapsePart = playlistVideos.slice(SHOW_PLAYLIST_NUMBER);
  }

  const hasPlaylist = playlistVideos.length > 1;

  let title;
  let currentVideoCaption;
  if (playlistTitle) {
    title = playlistTitle;
  } else if (vehicle) {
    const year = vehicle.year;
    const make = vehicle.make.name;
    const model = vehicle.model.name;
    title = `${year} ${make} ${model} ${classnames({ video: !hasPlaylist, videos: hasPlaylist })}`;

    if (currentVideoYear && +currentVideoYear !== +year) {
      currentVideoCaption = `NOTE: This video is about the ${currentVideoYear} ${make} ${model}, but since the ${year} ${make} ${model} is part of the same generation, our earlier analysis still applies.`;
    }
  } else {
    title = `Video ${classnames({ review: !hasPlaylist, reviews: hasPlaylist })}`;
  }

  const hasVideoDetailsCollapse = !hideVideoDetails && videoTranscript;
  const hasVideoDetails = !!(currentVideoCaption || videoDescription);
  const currentVideoUrl = `https://www.youtube.com/watch?v=${videoId}`;
  const VideoTitleTag = currentVideoCaption || videoDescription ? 'h3' : 'div';

  const videoDetails = (
    <Fragment>
      {!!videoTranscript && (
        <ContentCollapse
          textClosed="Transcript"
          textOpen="Close Transcript"
          justify={classnames('mx-0', { 'justify-content-end': !isModularDesign })}
          btnContainerClasses="w-100"
          classes={classnames({
            'mt-0_5': !isModularDesign,
            'mb-0_5': !isModularDesign || videoDescription || currentVideoCaption,
          })}
          btnClasses="transcript-btn p-0 text-gray-darker small"
          btnTextClasses="fw-medium mx-0"
          isEclipseFade={false}
          hideIndicator
        >
          {({ isOpen }) => (
            <div className="transcript-wrapper">
              <div
                className={classnames('transcript-box text-gray-darker h-100 px-1 py-0_5 mt-0_75', {
                  'd-none': !isOpen,
                })}
                aria-labelledby="video_title"
                role="tabpanel"
                tabIndex={0}
              >
                {videoTranscript}
              </div>
            </div>
          )}
        </ContentCollapse>
      )}
      <VideoTitleTag
        id="video_title"
        className={classnames('heading-5', {
          'mb-1_5': hasVideoDetails && !isModularDesign,
          'mb-0_5': hasVideoDetails && isModularDesign,
        })}
      >
        {videoTitle}
      </VideoTitleTag>
      {!!currentVideoCaption && (
        <p className={classnames('text-gray-darker size-16', { 'mb-0': !videoDescription })}>{currentVideoCaption}</p>
      )}
      {!!videoDescription && (
        <TruncatedTextLite
          className="description size-16 text-gray-darker mb-0"
          btnClassName="description-btn text-gray-darker fw-bold bg-transparent"
          isInlineBtn
          text={videoDescription}
          maxTextLength={(isModularDesign ? MAX_DESCRIPTION_LENGTH_MODULAR : MAX_DESCRIPTION_LENGTH)[isMobile]}
          btnTextA11y="about this video"
        />
      )}
    </Fragment>
  );

  return (
    <div
      className={classnames(
        'video-module',
        { 'rounded-12 bg-cool-gray-90 px-1 pt-1_5 pb-1_5': isModularDesign },
        className
      )}
      data-tracking-parent="edm-entry-video"
      {...withSchemaMeta && {
        itemScope: true,
        itemType: 'http://schema.org/VideoObject',
      }}
    >
      <Row className="d-flex">
        <Col tag="h2" xs={12} className="text-start heading-3 mb-1_5">
          {title}
        </Col>
        <Col xs={12} lg={videoLargeColumnSize}>
          <div
            className={classnames('pos-r', {
              'mb-1_5': !hasVideoDetailsCollapse && !isModularDesign,
              'mb-1': !hasVideoDetailsCollapse && isModularDesign,
              'rounded-12 overflow-hidden': isModularDesign,
            })}
          >
            <a
              className={classnames('video-title pos-a top-0 p-1', { 'mt-1 ml-1 rounded-8': isModularDesign })}
              href={currentVideoUrl}
            >
              {videoTitle}
            </a>
            <PlayerComponent
              videoId={videoId}
              videoTitle={videoTitle}
              creativeId="long-video"
              autoplay={false}
              changeHandler={changeHandler}
              className="embed-responsive-item"
              lazyLoadPreview={lazyLoadPreview}
              youtubeThumbnailSize={youtubeThumbnailSize}
              autoplayOnChange
            />
          </div>
          {withSchemaMeta && (
            <Fragment>
              <meta
                itemProp="thumbnailUrl"
                content={isDamSrc(thumbnailURL) ? getFullImageUrl(thumbnailURL) : thumbnailURL}
              />
              <meta itemProp="uploadDate" content={uploadDate} />
              <meta itemProp="name" content={videoTitle} />
              <meta itemProp="description" content={videoDescription} />
              <meta itemProp="transcript" content={videoTranscript} />
              <meta itemProp="contentUrl" content={`https://www.youtube.com/watch?v=${videoId}`} />
            </Fragment>
          )}
          {!hideVideoDetails && (isMobile || !isModularDesign) && videoDetails}
        </Col>
        {!hideVideoDetails && isModularDesign && !isMobile && (
          <Col xs={12} className="flex-lg-last">
            {videoDetails}
          </Col>
        )}
        {hasPlaylist && (
          <Col
            xs={12}
            lg={playlistLargeColumnSize}
            className={classnames(playlistClassName, { 'pl-lg-0': isPlaylistSameHeight })}
          >
            {/* Max-height greater than possible one to remove module jump on load */}
            <div
              className={classnames('playlist pos-r', { 'same-height': isPlaylistSameHeight })}
              style={isMobile ? {} : { maxHeight: '30rem' }}
            >
              <div
                className={classnames('playlist-box p-md-1 p-lg-0_5 p-xl-1', {
                  'rounded-12 bg-white border-0 px-1 py-1 ml-lg-0_5': isModularDesign,
                })}
              >
                <ul className="list-unstyled mb-0" aria-label="Videos">
                  {playlistShowPart.map((video, index) => (
                    <PlaylistItem
                      key={`video-${video.videoId}`}
                      video={video}
                      index={index}
                      loadVideo={loadVideo}
                      isCurrentVideo={video.videoId === videoId}
                      isPlaying={isPlaying}
                      playlistHasCurrentVideo={playlistHasCurrentVideo}
                      className={classnames({
                        'mb-1_5': video.videoId !== playlistVideos[playlistVideos.length - 1].videoId,
                      })}
                      thumbnailClassName={classnames({ 'rounded-8 overflow-hidden': isModularDesign })}
                      linkClassName={classnames({ 'rounded-8': isModularDesign })}
                      isMobile={isMobile}
                    />
                  ))}
                </ul>
                {!!get(playlistCollapsePart, 'length') && (
                  <ContentCollapse
                    textClosed="Show more videos"
                    textOpen="Show less"
                    justify="justify-content-center"
                    btnContainerClasses="m-0 p-0"
                    btnColor="outline-info"
                    btnClasses="size-16 py-0_5"
                    btnTextClasses="fw-normal"
                    showBtnBelow
                    hideCollapseBtn
                    hideIndicator
                    isEclipseFade={false}
                  >
                    <ul className="list-unstyled mb-0" aria-label="More videos">
                      {playlistCollapsePart.map((video, index) => (
                        <PlaylistItem
                          key={`video-${video.videoId}`}
                          video={video}
                          index={index}
                          loadVideo={loadVideo}
                          isCurrentVideo={video.videoId === videoId}
                          isPlaying={isPlaying}
                          playlistHasCurrentVideo={playlistHasCurrentVideo}
                          className={classnames({
                            'mb-1_5': video.videoId !== playlistVideos[playlistVideos.length - 1].videoId,
                          })}
                          thumbnailClassName={classnames({ 'rounded-8 overflow-hidden': isModularDesign })}
                          linkClassName={classnames({ 'rounded-8': isModularDesign })}
                          isMobile={isMobile}
                        />
                      ))}
                    </ul>
                  </ContentCollapse>
                )}
              </div>
            </div>
          </Col>
        )}
      </Row>
      {!!get(seeAllLink, 'href') && (
        <ArrowLink to={seeAllLink.href} className="mt-1_5">
          {seeAllLink.title}
        </ArrowLink>
      )}
    </div>
  );
}

VideoModuleUI.propTypes = {
  vehicle: VehicleEntities.MakeModelSubmodelYear,
  videos: MediaEntities.Videos,
  playerComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  pageName: PropTypes.string,
  playlistTitle: PropTypes.string,
  videoLargeColumnSize: PropTypes.number,
  playlistLargeColumnSize: PropTypes.number,
  playlistHasCurrentVideo: PropTypes.bool,
  isPlaylistSameHeight: PropTypes.bool,
  seeAllLink: PropTypes.shape({
    href: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  hideVideoDetails: PropTypes.bool,
  withSchemaMeta: PropTypes.bool,
  className: PropTypes.string,
  playlistClassName: PropTypes.string,
  youtubeThumbnailSize: PropTypes.oneOf(Object.values(YOUTUBE_PREVIEW_SIZE)),
  lazyLoadPreview: PropTypes.bool,
  isMobile: PropTypes.bool,
  isModularDesign: PropTypes.bool,
};

VideoModuleUI.defaultProps = {
  vehicle: null,
  videos: null,
  playerComponent: EmbeddedYoutubeAsync,
  pageName: null,
  playlistTitle: '',
  videoLargeColumnSize: 6,
  playlistLargeColumnSize: 4,
  playlistHasCurrentVideo: true,
  isPlaylistSameHeight: true,
  seeAllLink: null,
  hideVideoDetails: false,
  withSchemaMeta: true,
  className: 'mb-2 pb-0_75',
  playlistClassName: 'mt-2 mt-lg-0',
  youtubeThumbnailSize: undefined,
  lazyLoadPreview: false,
  isMobile: false,
  isModularDesign: false,
};

export const VideoVehicleModule = connectToModel(VideoModuleUI, {
  videos: bindToPath(
    ({ vehicle }) => buildVideosPath({ make: vehicle.make.slug, model: vehicle.model.slug, year: vehicle.year }),
    MediaModel,
    addVehicleVideoTitleFallback
  ),
});

VideoVehicleModule.propTypes = {
  vehicle: VehicleEntities.MakeModelSubmodelYear,
  pageName: PropTypes.string,
};

VideoVehicleModule.defaultProps = {
  vehicle: null,
  pageName: null,
};

export const VideoTypeModule = connectToModel(VideoModuleUI, {
  videos: bindToPath(({ type }) => `content["${getRankingsContentPath(type)}"]`, CmsModel, feed =>
    parseContent(feed)
      .child('video')
      .children()
      .map(child => child.getAllMetadata())
  ),
});

VideoTypeModule.propTypes = {
  type: PropTypes.string.isRequired,
  pageName: PropTypes.string,
};

VideoTypeModule.defaultProps = {
  pageName: null,
};

export const LatestVideoForGenerationModule = connectToModel(VideoModuleUI, {
  videos: bindToPath(
    ({ vehicle }) =>
      buildLatestVideosForGenerationPath({
        make: vehicle.make.slug,
        model: vehicle.model.slug,
        year: vehicle.year,
      }),
    MediaModel,
    addVehicleVideoTitleFallback
  ),
});

LatestVideoForGenerationModule.propTypes = {
  vehicle: VehicleEntities.MakeModelSubmodelYear,
};

LatestVideoForGenerationModule.defaultProps = {
  vehicle: null,
};
