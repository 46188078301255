import PropTypes from 'prop-types';
import { gql } from '@apollo/client'; // eslint-disable-line
import { get } from 'lodash';

import { EdmundsGraphQLFederation } from 'client/data/graphql/graphql-client';
import { withMetrics } from 'client/data/api/api-metrics';
import { createModelSegment } from 'client/data/luckdragon/segment';
import { transformVideos } from 'client/data/transforms/media/transform-videos';
import { fetchContent } from 'client/data/cms/fetch-content';

export function buildVideosPath({ make, model, year }) {
  return `makes["${make}"].models["${model}"].years["${year}"].videos`;
}

export function buildGenerationsPath({ makeSlug, modelSlug, year }) {
  return `makes["${makeSlug}"].models["${modelSlug}"].years["${year}"].generations`;
}

export function buildLatestVideosForGenerationPath({ make, model, year }) {
  return `makes["${make}"].models["${model}"].years["${year}"].latestVideosForGeneration`;
}

export const MediaEntities = {
  Videos: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        uploadDate: PropTypes.string,
        type: PropTypes.string,
        videoTitle: PropTypes.string,
        videoType: PropTypes.string,
        videoId: PropTypes.string,
        videoDescription: PropTypes.string,
        videoTranscript: PropTypes.string,
        thumbnailURL: PropTypes.string,
      })
    ),
    PropTypes.number,
  ]),
  Video: PropTypes.shape({
    uploadDate: PropTypes.string,
    type: PropTypes.string,
    videoTitle: PropTypes.string,
    videoType: PropTypes.string,
    videoId: PropTypes.string,
    videoDescription: PropTypes.string,
    videoTranscript: PropTypes.string,
    thumbnailURL: PropTypes.string,
  }),
};

const MAX_GENERATION_YEARS_LOOKBACK = 3;

export const MediaModel = createModelSegment('media', [
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].videos',
    resolve(match, context) {
      const { make, model, year } = match;
      const path = `${make}/${model}/${year}/photos`;
      return fetchContent(path, context)
        .then(apiResponse => transformVideos(apiResponse))
        .catch(() => null);
    },
  },
  /**
   * @see buildGenerationsPath
   * @returns {Array<SegmentRating>}
   */
  {
    path: 'makes["{makeSlug}"].models["{modelSlug}"].years["{year}"].generations',
    resolve({ makeSlug, modelSlug, year }, context) {
      return withMetrics(EdmundsGraphQLFederation, context)
        .query(
          gql`
            query($makeSlug: String!, $modelSlug: String!, $year: Int!) {
              modelYears(makeSlug: $makeSlug, modelSlug: $modelSlug, year: $year) {
                generations {
                  generation
                  generationYears
                }
              }
            }
          `,
          {
            makeSlug,
            modelSlug,
            year: parseInt(year, 10),
          }
        )
        .then(response => get(response, 'modelYears[0].generations[0]', null));
    },
  },
  {
    path: 'makes["{make}"].models["{model}"].years["{year}"].latestVideosForGeneration',
    async resolve(match, context) {
      const { make, model, year } = match;

      try {
        const generationData = await context.resolveValue(
          buildGenerationsPath({ makeSlug: make, modelSlug: model, year })
        );

        // Fallback to video path if generation data is not available
        if (!generationData) {
          const videos = await context.resolveValue(buildVideosPath({ make, model, year }));
          return videos;
        }

        const { generationYears } = generationData;
        const transformedYears = generationYears.filter(
          genYear => genYear <= year && genYear >= year - MAX_GENERATION_YEARS_LOOKBACK
        );
        let videoYear = transformedYears.pop();

        // Get the latest available videos for this generation
        while (videoYear) {
          // eslint-disable-next-line no-await-in-loop
          const genYearVideos = await context.resolveValue(buildVideosPath({ make, model, year: videoYear }));
          if (genYearVideos && genYearVideos.length) {
            // eslint-disable-next-line no-loop-func
            return genYearVideos.map(video => ({ ...video, year: videoYear }));
          }
          videoYear = transformedYears.pop();
        }

        return [];
      } catch (e) {
        return [];
      }
    },
  },
]);
